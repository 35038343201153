import Vue from 'vue';
import Vuex from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {NumberFilter} from '@/shared/filter';
import {
	PaginatorActionWithFilters,
	PaginatorFilterState,
	PaginatorMutationFilter,
	StandardAction
} from '@/shared/utils';
import {BoisType, CubageChantierTest} from '@/models';
import {CubageChantierTestService} from '@/xhttp';
import store from '@/stores';


Vue.use(Vuex); 

export class CubageChantierTestFilter {

	public boisType:     BoisType[] = [];
	
	public toString(): string {
		return JSON.stringify({
			boisType    : this.boisType.map(b => b.id),
		});
	}
}

class CubageChantierTestState extends PaginatorFilterState<CubageChantierTestFilter> {
	public cubageChantierTest: CubageChantierTest = null;
	public cubageChantierTests: ResultType<CubageChantierTest> = null;
	public constructor() {
		super(new CubageChantierTestFilter(), 'cubageChantierTests', 'cubageChantierTest');
	}
}

class CubageChantierTestStore {
	
	@XHTTPService(() => CubageChantierTest)
	private cubageChantierTestService: CubageChantierTestService;
	
	public state: CubageChantierTestState = new CubageChantierTestState();
	
	public mutations = {
		...PaginatorMutationFilter(() => storeCubageChantierTest, 'cubageChantierTests', 'cubageChantierTest'),
	};
	
	public actions = {
		...PaginatorActionWithFilters<CubageChantierTest, CubageChantierTestState>(() => CubageChantierTest, 'cubageChantierTests', 'cubageChantierTest'),
		...StandardAction<CubageChantierTest, CubageChantierTestState>(() => CubageChantierTest, 'cubageChantierTest'),
	};
	
}

const instance = new CubageChantierTestStore();
const storeCubageChantierTest = new Vuex.Store(instance);
store.registerModule('cubageChantierTest', storeCubageChantierTest);
export default storeCubageChantierTest;

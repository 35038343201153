

























































































































































	import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {Tiers, TiersType, Token, User} from '@/models';
	import CrudAddressEdit from '@/components/Crud/Address/Edit.vue';

	import storeTiers from '@/stores/modules/tiers';

	import InputPhone from '@/components/Input/Phone.vue';
	import storeToken from "@/stores/modules/token";

	import axios from "axios";
	import { Stored } from 'vue-stored-prop-decorator';
	import storeUser from "@/stores/modules/user";

	@Component({
		components: {
			InputPhone,
			CrudAddressEdit,
		}
	})
	export default class CrudTiersEdit extends AbstractEditVue<Tiers> {

		private step: number = 1;
		private showResult: number = 0;
		private array: any = [];
		private year: string = '';
		private montantLettre: string = '';
		private montant: number = 0;
		private object = {};

		@Stored(() => storeUser) me!: User;
        @Stored(() => storeToken)      private token: Token;

		public constructor() {
			super(
				storeTiers,
				'Tiers modifié',
				'Tiers créé',
			);
		}

		private selected = [];
		private headers = [
			{
				text: 'Numéro contrat',
				sortable: false,
				value: 'numero',
			},
			{ text: 'Code chantier', value: 'chantier_name' },
			{ text: 'Commune', value: 'commune' },
			{ text: 'Type de prestation', value: 'name' },
			{ text: 'Prix', value: 'Montant' },
		];

		@Emit()
		private onNext() {
			axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/tierses/attestation/" + this.value.id + "/" + this.year + '?token=' + this.token.id, {}).then(response => {
				this.array = response.data;
				this.showResult = 1;
			});
			this.step = 2;
		}

		@Emit()
		private onNext2() {
			for (let i=0; i<this.selected.length; i++) {
				this.montant += parseInt(this.selected[i].Montant);
			}
			this.step = 3;
		}

		@Emit()
		private onNext3() {
			this.object = {
				name: this.value.name,
				company: this.value.company,
				adresse: this.value.address,
				montantLettre: this.montantLettre,
				montant: this.montant,
			};
			this.step = 4;
		}

		private get hrefPdf(): string {
			return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/tiers/${this.value.id}/${this.montant}/${this.montantLettre}/${this.year}?token=${this.token.id}`;
		}

		protected async onSubmit(): Promise<void> {
			super.onSubmit();
			this.step = 1;
		}

		public async mounted(): Promise<void> {
			await super.mounted();
		}

		public async refresh(): Promise<void> {
			await super.refresh();
			//console.log('route tiers edit = ' + this.$route.name);
			if (this.$route.name === 'tiers-edit') {
				this.$route.meta.title = this.item.type === TiersType.PROPRIETAIRE ? 'Édition  d\'un propriétaire' : 'Édition  d\'une scierie';
			}
		}

	}

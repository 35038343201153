import {XHTTP} from '@/shared/xhttp';
import {CubageLivraisonService} from '@/xhttp';
import {DateHelper} from "@/shared/utils";
import {BoisType} from './BoisType';
import {BoisQuality} from './BoisQuality';
import {BoisSize} from './BoisSize';
import {Marquage} from './Marquage';
import {Chantier} from './Chantier';
import {Livraison} from './Livraison';
import {Purchase} from "./Purchase";
import {Tiers} from "./Tiers";
import {Chauffeur} from '@/models/Chauffeur';
import {DocumentCubageLivraison} from "./DocumentCubageLivraison";
import {CubageLivraisonPlateforme} from "@/models/CubageLivraisonPlateforme";

@XHTTP(CubageLivraisonService, '/cubage-livraisons')
export class CubageLivraison {

	private _id: number;
	private _date: Date = new Date();
	private _chauffeur: Chauffeur;
	private _bl: string;
	private _numfacture: string;
	private _livraison: Livraison;
	private _scierie: Tiers;
	private _purchase: Purchase;
	private _chantier: Chantier;
	private _estimer: boolean;
	private _boisFini: boolean;
	private _numero: string;
	private _boisType: BoisType;
	private _boisQuality: BoisQuality;
	private _boisSize: BoisSize;
	private _marquage: Marquage;
	private _volumeInner: number;
	private _volumeApproximatif: boolean = true;
	private _volumeOutter: number;
	private _coef: number;
	private _volumeStere: number;
	private _coefStere: number;
	private _volumeTonne: number;
	private _coefTonne: number = 1;
	private _prixM3: number;
	private _prixStere: number;
	private _prixTonne: number;
	private _prixCamion: number;
	private _changeCoeff: boolean = false;
	private _documents: DocumentCubageLivraison[] = [];
	private _sansMarquage: boolean = false; // variable de gestion uniquement dans le front
	private _cubageLivraisonPlateforme: CubageLivraisonPlateforme;

	private _volumeEstimeRestant: string = ''; // uniquement pour gestion interne coté front
	private _selected: boolean = false;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get date(): Date {
		return this._date;
	}

	public get livraison(): Livraison {
		return this._livraison;
	}

	public get chauffeur(): Chauffeur {
		return this._chauffeur;
	}

	public get bl(): string {
		return this._bl;
	}

	public get numfacture(): string {
		return this._numfacture;
	}

	public get volumeEstimeRestant(): string {
		return this._volumeEstimeRestant;
	}

	public get scierie(): Tiers {
		return this._scierie;
	}

	public get purchase(): Purchase {
		return this._purchase;
	}
	
	public get sansMarquage(): boolean {
		return this._sansMarquage;
	}

	public get estimer(): boolean {
		return this._estimer;
	}

	public get boisFini(): boolean {
		return this._boisFini;
	}

	public get changeCoeff(): boolean {
		return this._changeCoeff;
	}

	public get volumeApproximatif(): boolean {
		return this._volumeApproximatif;
	}

	public get chantier(): Chantier {
		return this._chantier;
	}

	public get numero(): string {
		return this._numero;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get boisQuality(): BoisQuality {
		return this._boisQuality;
	}

	public get boisSize(): BoisSize {
		return this._boisSize;
	}

	public get marquage(): Marquage {
		return this._marquage;
	}

	public get volumeInner(): number {
		return this._volumeInner;
	}

	public get volumeOutter(): number {
		return this._volumeOutter;
	}

	public get coef(): number {
		return this._coef;
	}

	public get volumeStere(): number {
		return this._volumeStere;
	}

	public get coefStere(): number {
		return this._coefStere;
	}

	public get volumeTonne(): number {
		return this._volumeTonne;
	}

	public get coefTonne(): number {
		return this._coefTonne;
	}

	public get prixM3(): number {
		return this._prixM3;
	}

	public get prixStere(): number {
		return this._prixStere;
	}

	public get prixTonne(): number {
		return this._prixTonne;
	}

	public get prixCamion(): number {
		return this._prixCamion;
	}

	public get documents(): DocumentCubageLivraison[] {
		return this._documents;
	}

	public get cubageLivraisonPlateforme(): CubageLivraisonPlateforme {
		return this._cubageLivraisonPlateforme;
	}

	public get selected(): boolean {
		return this._selected;
	}

	/////////
	// Add //
	/////////

	public addDocuments(document: DocumentCubageLivraison): this {
		document.cubage = this;
		if (this._documents.indexOf(document) === -1) {
			this._documents.push(document);
		}
		return this;
	}

	public removeDocuments(document: DocumentCubageLivraison): this {
		document.cubage = this;
		const index = this._documents.indexOf(document);
		if (index !== -1) {
			this._documents.splice(index, 1);
		}
		return this;
	}
	
	/////////////
	// Setters //
	/////////////

	public set date(value: Date) {
		this._date = value;
	}

	public set bl(value: string) {
		this._bl = value;
	}

	public set numfacture(value: string) {
		this._numfacture = value;
	}

	public set volumeEstimeRestant(value: string) {
		this._volumeEstimeRestant = value;
	}

	public set livraison(value: Livraison) {
		this._livraison = value;
	}
	
	public set scierie(value: Tiers) {
		this._scierie = value;
	}
	
	public set chauffeur(value: Chauffeur) {
		this._chauffeur = value;
	}

	public set purchase(value: Purchase) {
		this._purchase = value;
	}

	public set chantier(value: Chantier) {
		this._chantier = value;
	}

	public set boisFini(value: boolean) {
		this._boisFini = value;
	}

	public set changeCoeff(value: boolean) {
		this._changeCoeff = value;

		if (this._changeCoeff == true)
		{
			this.coefStere = 0;
			this.coefTonne = 0;
		}
		else
		{
			//this.coefStere = 0.63;
			//this.coefTonne = 1;
		}
	}

	public set sansMarquage(value: boolean) {
		this._sansMarquage = value;
	}

	public set volumeApproximatif(value: boolean) {
		this._volumeApproximatif = value;
	}

	public set estimer(value: boolean) {
		this._estimer = value;
	}

	public set numero(value: string) {
		this._numero = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set boisQuality(value: BoisQuality) {
		this._boisQuality = value;
	}

	public set boisSize(value: BoisSize) {
		this._boisSize = value;
	}

	public set marquage(value: Marquage) {
		this._marquage = value;
	}

	public set volumeInner(value: number) {
		this._volumeInner = value;
		if (this._volumeInner) {
			if (this._coef) {
				this._volumeOutter = Math.round(this._volumeInner / this._coef * 100 ) / 100;
			} 
			if (this._coefStere) {
				this._volumeStere = Math.round(this._volumeInner / this._coefStere * 100 ) / 100;
			} 
			if (this._coefTonne) {
				this._volumeTonne = Math.round(this._volumeInner / this._coefTonne * 100 ) / 100;
			} 
		}

		if (this._prixM3 != 0)
		{
			this._prixCamion = this._prixM3 * this._volumeInner;
			this._prixCamion = Math.round((this._prixCamion + Number.EPSILON) * 100) / 100;
		}
		else
		{
			if ((this._prixCamion != 0) && (this._volumeInner != 0))
			{
				this._prixM3 = this._prixCamion / this._volumeInner;
				this._prixM3 = Math.round((this._prixM3 + Number.EPSILON) * 100) / 100;
			}
			else
			{
				this._prixM3 = 0;
			}
		}
	}

	public set volumeOutter(value: number) {
		this._volumeOutter = value;
		if (this._volumeOutter) {
			if (this._coef) {
				// volume inner, met a jour tout le reste
				this.volumeInner = Math.round(this._volumeOutter * this._coef * 100 ) / 100;
			} 
		}
	}

	public set volumeStere(value: number) {
		this._volumeStere = value;

		if (this._changeCoeff == true)
		{
			if (this.volumeInner > 0) {
				if (this._volumeStere > 0) {
					this._coefStere = Math.round(this.volumeInner / this._volumeStere * 100) / 100;
				}
			}
		}
		else
		{
			if (this._volumeStere) {
				if (this._coefStere) {
					// volume inner, met a jour tout le reste
					this.volumeInner = Math.round(this._volumeStere * this._coefStere * 100 ) / 100;
				} 
			}
		}
	}

	public set volumeTonne(value: number) {
		this._volumeTonne = value;

		if (this._changeCoeff == true)
		{
			if (this.volumeInner > 0) {
				if (this._volumeTonne > 0) {
					this._coefTonne = Math.round(this.volumeInner / this._volumeTonne * 100) / 100;
				}
			}
		}
		else
		{
			if (this._volumeTonne) {
				if (this._coefTonne) {
					// volume inner, met a jour tout le reste
					this.volumeInner = Math.round(this._volumeTonne * this._coefTonne * 100 ) / 100;
				} 
			}
		}
	}

	public set coef(value: number) {
		this._coef = value;

		if (this._coef) {
			if (this._volumeOutter) {
				// volume inner, met a jour tout le reste
				this.volumeInner = Math.round(this._volumeOutter * this._coef * 100 ) / 100;
			} 
		}
	}

	public set coefStere(value: number) {
		this._coefStere = value;
		if (this._coefStere) {
			if (this._volumeOutter) {
				// volume inner, met a jour tout le reste
				this.volumeInner = Math.round(this._volumeStere * this._coefStere * 100 ) / 100;
			} 
			if (this._prixStere) {
				// prix m3, met a jour tout le reste
				this.prixM3 = Math.round(this._prixStere / this._coefStere * 100 ) / 100;
			} 
		}
	}

	public set coefTonne(value: number) {
		this._coefTonne = value;
		if (this._coefTonne) {
			if (this._volumeTonne) {
				// volume inner, met a jour tout le reste
				this.volumeInner = Math.round(this._volumeTonne * this._coefTonne * 100 ) / 100;
			} 
			if (this._prixTonne) {
				// prix m3, met a jour tout le reste
				this.prixM3 = Math.round(this._prixTonne / this._coefTonne * 100 ) / 100;
			} 
		}
	}

	public set prixM3(value: number) {
		this._prixM3 = value;
		this._prixCamion = this._prixM3 * this._volumeInner;
		this._prixCamion = Math.round((this._prixCamion + Number.EPSILON) * 100) / 100;
		if (this.coefStere) {
			this._prixStere = this._prixM3 * this.coefStere;
			this._prixStere = Math.round((this._prixStere + Number.EPSILON) * 100) / 100;
		} 
		if (this._coefTonne) {
			this._prixTonne = this._prixM3 * this._coefTonne;
			this._prixTonne = Math.round((this._prixTonne + Number.EPSILON) * 100) / 100;
		} 
	}

	public set prixStere(value: number) {
		this._prixStere = value;
		if (this.coefStere) {
			// prix m3, met a jour tout le reste
			this._prixM3 = this._prixStere / this.coefStere;
			this._prixM3 = Math.round((this._prixM3 + Number.EPSILON) * 100) / 100;
			this._prixCamion = this._prixM3 * this._volumeInner;
			this._prixCamion = Math.round((this._prixCamion + Number.EPSILON) * 100) / 100;
			if (this._coefTonne) {
				this._prixTonne = this._prixM3 * this._coefTonne;
				this._prixTonne = Math.round((this._prixTonne + Number.EPSILON) * 100) / 100;
			} 
	
		} 
	}

	public set prixTonne(value: number) {
		this._prixTonne = value;
		if (this.coefTonne) {
			// prix m3, met a jour tout le reste
			this._prixM3 = this._prixTonne / this.coefTonne;
			this._prixM3 = Math.round((this._prixM3 + Number.EPSILON) * 100) / 100;
			this._prixCamion = this._prixM3 * this._volumeInner;
			this._prixCamion = Math.round((this._prixCamion + Number.EPSILON) * 100) / 100;
			if (this.coefStere) {
				this._prixStere = this._prixM3 * this.coefStere;
				this._prixStere = Math.round((this._prixStere + Number.EPSILON) * 100) / 100;
			} 
		} 
}

	public set prixCamion(value: number) {
		this._prixCamion = value;
		if (this._volumeInner != 0)
		{
			this._prixM3 = this._prixCamion / this._volumeInner;
			this._prixM3 = Math.round((this._prixM3 + Number.EPSILON) * 100) / 100;

			if (this._volumeStere)
			{
				this._prixStere = this._prixCamion / this._volumeStere;
				this._prixStere = Math.round((this._prixStere + Number.EPSILON) * 100) / 100;
			}

			if (this._volumeTonne)
			{
				this._prixTonne = this._prixCamion / this._volumeTonne;
				this._prixTonne = Math.round((this._prixTonne + Number.EPSILON) * 100) / 100;
			}
		}
		else
		{
			this._prixM3 = 0;
			this._prixStere = 0;
		}
	}

	public set cubageLivraisonPlateforme(value: CubageLivraisonPlateforme) {
		this._cubageLivraisonPlateforme = value;
	}

	public set selected(value: boolean) {
		this._selected = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			date    : this._date ? DateHelper.toLocalizedString(this._date) : null,
			chauffeur  : this._chauffeur ? this._chauffeur.id : null,
			bl	: this._bl,
			numfacture	: this._numfacture,
			livraison         : this._livraison ? this._livraison.id : null,
			scierie         : this._scierie ? this._scierie.id : null,
			purchase: this._purchase ? this._purchase.id : null,
			chantier    : this._chantier ? this._chantier.id : null,
			estimer      : this._estimer,
			boisFini      : this._boisFini,
			volumeApproximatif      : this._volumeApproximatif,
			numero      : this._numero,
			boisType    : this._boisType ? this._boisType.id : null,
			boisQuality : this._boisQuality ? this._boisQuality.id : null,
			boisSize    : this._boisSize ? this._boisSize.id : null,
			marquage    : this._marquage ? this._marquage : null,
			volumeInner : this._volumeInner,
			volumeOutter: this._volumeOutter,
			coef        : this._coef,
			volumeStere: this._volumeStere,
			coefStere        : this._coefStere,
			volumeTonne: this._volumeTonne,
			coefTonne        : this._coefTonne,
			prixM3        : this._prixM3,
			prixStere        : this._prixStere,
			prixTonne        : this._prixTonne,
			prixCamion        : this._prixCamion,
			cubageLivraisonPlateforme    : this._cubageLivraisonPlateforme ? this._cubageLivraisonPlateforme.id : null,
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._date         = data.date ? DateHelper.fromLocalizedString(data.date) : null;
		this._chauffeur    = data.chauffeur ? (new Chauffeur()).fromJSON(data.chauffeur) : null;
		this._bl 		   = data.bl;
		this._numfacture 		   = data.numfacture;
		this._livraison      = data.livraison ? (new Livraison).fromJSON(data.livraison) : null;
		this._scierie      = data.scierie ? (new Tiers).fromJSON(data.scierie) : null;
		this._purchase     = data.purchase ? (new Purchase()).fromJSON(data.purchase) : null;
		this._chantier     = data.chantier ? (new Chantier()).fromJSON(data.chantier) : null;
		this._estimer      = data.estimer;
		this._boisFini     = data.boisFini;
		this._volumeApproximatif     = data.volumeApproximatif;
		this._numero       = data.numero;
		this._boisType     = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._boisQuality  = data.boisQuality ? (new BoisQuality()).fromJSON(data.boisQuality) : null;
		this._boisSize     = data.boisSize ? (new BoisSize()).fromJSON(data.boisSize) : null;
		this._marquage     = data.marquage ? (new Marquage()).fromJSON(data.marquage) : null;
		this._volumeInner  = data.volumeInner;
		this._volumeOutter = data.volumeOutter;
		this._coef         = data.coef;
		this._volumeStere = data.volumeStere;
		this._coefStere         = data.coefStere;
		this._volumeTonne = data.volumeTonne;
		this._coefTonne         = data.coefTonne;
		this._prixM3         = data.prixM3;
		this._prixStere         = data.prixStere;
		this._prixTonne         = data.prixTonne;
		this._prixCamion     = data.prixCamion;
		if (data.documentCubageLivraisons)  {
			for (const d of data.documentCubageLivraisons) {
				this.addDocuments((new DocumentCubageLivraison()).fromJSON(d));
			}
		}
		this._cubageLivraisonPlateforme     = data.cubageLivraisonPlateforme ? (new CubageLivraisonPlateforme()).fromJSON(data.cubageLivraisonPlateforme) : null;
		return this;
	}
}






























































































































































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {Chantier, CubageLivraison} from "@/models";
	import {ArrayHelper} from "@/shared/utils";
  	import Checked from '@/components/Checked.vue';
	import {ResultType} from '@/shared/xhttp';

	import storeCubageLivraison, {CubageLivraisonFilter} from '@/stores/modules/cubageLivraison';

	import CrudList from '@/components/Crud/List.vue';
	import TableHead from '@/components/Paginate/TableHead.vue';
	import TableCell from '@/components/Paginate/TableCell.vue';

	@Component({
		components: {
			CrudList,
			TableHead,
			TableCell,
			Checked,
		}
	})
	export default class CrudCubageLivraisonList extends Vue {

		@Prop({ type: Boolean   , default: true  }) public paginate!: boolean;
		@Prop({ type: Boolean   , default: false }) public dense!   : boolean;
		@Prop({ type: [ Chantier, Boolean ] , default: false }) public chantier: Chantier;
		@Prop({ type: Boolean   , default: false }) public avecAction2!   : boolean;

		@Stored(() => storeCubageLivraison) private filters!: CubageLivraisonFilter;
		@Stored(() => storeCubageLivraison)	private cubageLivraisons!: ResultType<CubageLivraison>;
		
		private storeCubageLivraison = storeCubageLivraison;

		private totalLivraisonm3: number = 0;
		private totalLivraisonsteres: number = 0;
		private totalVentes: number = 0;

		@Watch('cubageLivraisons')
		public calculTotaux(): void {
			//console.log("filter");
			// on calcul le total
			//console.log(this.prestations);
			this.totalLivraisonm3 = 0;
			this.totalLivraisonsteres = 0;
			this.totalVentes = 0;
			for (const cubage of this.cubageLivraisons.data) {
				if (cubage.boisSize.grume == false)
					this.totalLivraisonsteres = this.totalLivraisonsteres + cubage.volumeStere;
				else
					this.totalLivraisonm3 = this.totalLivraisonm3 + cubage.volumeInner;

				this.totalVentes = this.totalVentes + cubage.prixCamion;
			}

		}

		public get show(): boolean {
			return this.chantier !== null;
		}

		public mounted(): void {
			this.watcher();
			this.storeCubageLivraison.state.limit = 200;
		}

		@Watch('chantier')
		public watcher(): void {
			if (this.chantier) {
				this.filters.clear();
				this.filters.chantier = [ this.chantier ];
			} else {
				this.filters.chantier = [];
			}
		}
	}

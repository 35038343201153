




























































































































































































































    import {Emit} from "vue-property-decorator";
	import {Component} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator";
	import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
    import {Chantier, CubageChantierTest, BoisType, Marquage, Token, User} from "@/models";
	import Checked from '@/components/Checked.vue';
	import storeChantier from '@/stores/modules/chantier';
	import storeLoader from "@/stores/modules/loader";
	import storeToken from "@/stores/modules/token";
	import storeUser from '@/stores/modules/user';
	import CrudCubageChantierTestEditDialog from "@/components/Crud/CubageChantierTest/EditDialog.vue";
	import axios from "axios";
	import storeCubageChantierTest from '@/stores/modules/cubageChantierTest';
	import CrudDeleteDialog from "@/components/Crud/DeleteDialog.vue";

	@Component({
		components: {
			Checked,
			CrudDeleteDialog,
			storeCubageChantierTest,
			CrudCubageChantierTestEditDialog,
		}
	})
	export default class CrudChantierCompareCubageShow extends AbstractShowVue<Chantier> {

		@Stored(() => storeLoader)     private loading: boolean;
        @Stored(() => storeToken)      private token: Token;
		@Stored(() => storeUser) me!: User;

		private infos = null;
		private editCubageChantierTest: CubageChantierTest = null;
		private deletedCubageChantierTest: CubageChantierTest = null;
		private storeCubageChantierTest = storeCubageChantierTest;

		private get hrefPdf(): string {
			return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/chantier/cubage-test/${this.item.id}?token=${this.token.id}`;
		}

		public constructor() {
			super(storeChantier);
		}
		
		public newCubageTest()
		{
			var newCubage = new CubageChantierTest();
			newCubage.chantier = this.item;
			newCubage.surEcorce = true;
			// Nom user
			newCubage.username = this.me.fullName;
			var iCounter: number = 1;
			var otypeBois: BoisType = null;
			var omarquage: Marquage = null;
			// Si on en a deja, on va chercher le numero
			this.infos.data.forEach((value, index) => {
				if (value.numero != null)
				{
					if (value.numero >= iCounter)
					{
						iCounter = parseInt(value.numero) + 1;
					}				
				}				
				
				if (value.bois_type_id != null)
				{
					otypeBois = new BoisType();
					otypeBois.id = Number(value.bois_type_id);
				}
				if (value.marquage_id != null)
				{
					omarquage = new Marquage();
					omarquage.id = Number(value.marquage_id);
				}
			});

			if (otypeBois != null)
			{
				newCubage.boisType = otypeBois;
			}
			if (omarquage != null)
			{
				newCubage.marquage = omarquage;
			}
			newCubage.numero = iCounter;

			this.editCubageChantierTest = newCubage;
		}

		private async modifyCubageChantierTest(ID : number): Promise<void> 
		{
			var oCubageChantierTest = await storeCubageChantierTest.dispatch('get', ID);

			this.editCubageChantierTest = oCubageChantierTest;
		}		

		private async deleteCubageChantierTest(ID : number): Promise<void> 
		{
			var oCubageChantierTest = await storeCubageChantierTest.dispatch('get', ID);

			this.deletedCubageChantierTest = oCubageChantierTest;
		}		

		public async mounted(): Promise<void> {
			await super.mounted();
			this.RefreshData();
		}

		public async RefreshData(): Promise<void> 
		{
			await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantier-tests/getAll/" + this.item.id + "?token=" + this.token.id, {}).then(async response => {
					this.infos = await response.data;
			});

		}

		private getFormattedDate(dateString): String{
			if (dateString == '') return '';
			if (dateString == null) return '';

            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', { month: 'numeric', day: 'numeric', year: 'numeric', }).format(date);
		}

	}

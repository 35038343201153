import {XHTTP} from '@/shared/xhttp';
import {MarquageService} from '@/xhttp';

@XHTTP(MarquageService, '/marquages')
export class Marquage {
	
	private _id: number;
	private _name: string;
	private _color: string;
	private _use: boolean;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}

	public get name(): string {
		return this._name;
	}

	public get color(): string {
		return this._color;
	}

	public get use(): boolean {
		return this._use;
	}
	
	/////////////
	// Setters //
	/////////////

	public set name(value: string) {
		this._name = value;
	}

	public set id(value: number) {
		this._id = value;
	}

	public set color(value: string) {
		this._color = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id     : this._id,
			name   : this._name,
			color   : this._color,
		};
	}
	
	public fromJSON(data: any): this {
		this._id   = data.id;
		this._name = data.name;
		this._color = data.color;
		this._use  = data.use;
		return this;
	}

	////////////
	// Others //
	////////////
	
	public toString(): string {
		return this.name;
	}
	
}
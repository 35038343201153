









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {Emit, Prop, Watch} from "vue-property-decorator";
import {Component} from '@/shared/component';
import {Stored} from "vue-stored-prop-decorator";
import {AbstractShowVue} from '@/components/Abstract/AbstractShowVue';
import {Chantier, CubageChantier, CubageLivraison, Livraison, CubagePlateforme, Prestation, Token, User} from "@/models";
import storeCubageChantierRestant, {CubageChantierRestantFilter} from "@/stores/modules/cubageChantierRestant";
import {WatchObjectProperties} from '@/shared/decorator';
import {ResultType} from '@/shared/xhttp';
import axios from "axios";

import storePrestation, {PrestationFilter, PrestationState} from '@/stores/modules/prestation';
import storeLoader from "@/stores/modules/loader";
import storeChantier from '@/stores/modules/chantier';
import storeCubageChantier from "@/stores/modules/cubageChantier";
import storeToken from "@/stores/modules/token";
import storeUser from '@/stores/modules/user';
import storeCubageLivraison, {CubageLivraisonFilter} from '@/stores/modules/cubageLivraison';

import Checked from '@/components/Checked.vue';
import CrudChantierEditDialog from '@/components/Crud/Chantier/EditDialog.vue';
import CrudChantierEditDialogFinancier from '@/components/Crud/Chantier/EditDialogFinancier.vue';
import CrudChantierEditDialogFraisChantiers from '@/components/Crud/Chantier/EditDialogFraisChantiers.vue';
import CrudChantierEditDialogPlanning from '@/components/Crud/Chantier/EditDialogPlanning.vue';
import CrudContactShowReduce from '@/components/Crud/Contact/ShowReduce.vue';
import CrudPrestationFilter from '@/components/Crud/Prestation/Filter.vue';
import CrudPrestationList from '@/components/Crud/Prestation/ListChantier.vue';
import CrudPrestationSearch from '@/components/Crud/Prestation/Search.vue';
import CrudPrestationEditDialog from '@/components/Crud/Prestation/EditDialog.vue';
import CrudCubageLivraisonFilter from '@/components/Crud/CubageLivraison/Filter.vue';
import CrudCubageLivraisonList from '@/components/Crud/CubageLivraison/List.vue';
import CrudCubagePlateformeListForChantier from '@/components/Crud/CubagePlateforme/ListForChantier.vue';
import CrudCubageLivraisonEditDialogCreate from '@/components/Crud/CubageLivraison/EditDialogCreate.vue';
import CrudCubageLivraisonEditDialog from '@/components/Crud/CubageLivraison/EditDialog.vue';
import CrudDeleteDialog from '@/components/Crud/DeleteDialog.vue';
import DialogConfirm from '@/components/Dialog/Confirm.vue';
import CrudCubageChantierFilter from '@/components/Crud/CubageChantier/Filter.vue';
import CrudCubageChantierList from '@/components/Crud/CubageChantier/List.vue';
import CrudCubageChantierEditDialog from '@/components/Crud/CubageChantier/EditDialog.vue';
import CrudCubageChantierEditDocumentDialog from '@/components/Crud/CubageChantier/EditDialogDocument.vue';
import CrudCubageLivraisonEditDocumentDialog from '@/components/Crud/CubageLivraison/EditDialogDocument.vue';
import CrudCubageLivraisonListDialogForCubageChantier from '@/components/Crud/CubageLivraison/ListDialogForCubageChantier.vue';
import CrudDocumentChantierShowDocsPrestations from '@/components/Crud/DocumentChantier/ShowDocsPrestations.vue';
import CrudDocumentChantierShowPhoto from '@/components/Crud/DocumentChantier/ShowPhoto.vue';
import CrudCubageChantierRestantFilter from '@/components/Crud/CubageChantierRestant/Filter.vue';
import CrudCubageChantierRestantList from '@/components/Crud/CubageChantierRestant/List.vue';
import CrudCubagePlateformeEditDialogCreate from "@/components/Crud/CubagePlateforme/EditDialogCreate.vue";
import CrudCubagePlateformeEditDialog from "@/components/Crud/CubagePlateforme/EditDialog.vue";
import CrudChantierEditDialogMessageOperateur from "@/components/Crud/Chantier/EditDialogMessageOperateur.vue";
import storeCubagePlateforme from "@/stores/modules/cubagePlateforme";
import {Store} from "vuex";
import CrudDocumentChantierUploaderPhoto from '@/components/Crud/DocumentChantier/UploaderPhoto.vue';

import CrudChantierPhotosEditDialog from "@/components/Crud/Chantier/EditPhotosDialog.vue";
import CrudDocumentChantierUploaderDocumentChauffeur from '@/components/Crud/DocumentChantier/UploaderDocumentChauffeur.vue';
import CrudDocumentChauffeurChantierShow from '@/components/Crud/DocumentChantier/ShowDocumentChauffeur.vue';
import CrudLivraisonEditDialog from '@/components/Crud/Livraison/EditDialog.vue';
import CrudLivraisonEditDialogCreate from '@/components/Crud/Livraison/EditDialogCreate.vue';
import storeLivraison from '@/stores/modules/livraison';
import {PaginatorState} from '@/shared/utils';

@Component({
	components: {
		CrudDocumentChantierUploaderDocumentChauffeur,
		CrudDocumentChauffeurChantierShow,
		CrudChantierPhotosEditDialog,
		CrudDocumentChantierUploaderPhoto,
		CrudChantierEditDialog,
		CrudChantierEditDialogFinancier,
		CrudChantierEditDialogPlanning,
		CrudChantierEditDialogFraisChantiers,
		CrudContactShowReduce,
		CrudPrestationFilter,
		CrudPrestationList,
		CrudPrestationSearch,
		CrudPrestationEditDialog,
		CrudCubageLivraisonFilter,
		CrudCubageLivraisonList,
		CrudCubagePlateformeListForChantier,
		CrudCubagePlateformeEditDialogCreate,
		CrudCubagePlateformeEditDialog,
		CrudCubageLivraisonEditDialogCreate,
		CrudCubageLivraisonEditDialog,
		CrudCubageLivraisonEditDocumentDialog,
		CrudCubageLivraisonListDialogForCubageChantier,
		CrudDeleteDialog,
		DialogConfirm,
		CrudCubageChantierFilter,
		CrudCubageChantierList,
		CrudCubageChantierEditDialog,
		CrudCubageChantierEditDocumentDialog,
		Checked,
		CrudDocumentChantierShowDocsPrestations,
		CrudDocumentChantierShowPhoto,
		CrudCubageChantierRestantList,
		CrudCubageChantierRestantFilter,
		CrudChantierEditDialogMessageOperateur,	
		CrudLivraisonEditDialog,
		CrudLivraisonEditDialogCreate,	
		storeLivraison,
	}
})
export default class CrudChantierShow extends AbstractShowVue<Chantier> {

	@Stored(() => storeLoader) private loading: boolean;
	@Stored(() => storeToken) private token: Token;
	@Stored(() => storeCubageChantierRestant, 'filters') private cubageChantierRestantFilter!: CubageChantierRestantFilter;
	@Stored(() => storeUser) me!: User;
	@Stored(() => storeCubageLivraison, 'filters') private filtersLivraisons!: CubageLivraisonFilter;
	@Stored(() => storeChantier) private chantier!: ResultType<Chantier>;

	private isOperateurOnbly: boolean = false;

	private addPrestationDialog: boolean = false;
	private addPrestationList: Prestation[] = [];

	private markDebutChantierConfirm: boolean = false;
	private markFinChantierConfirm: boolean = false;
	private newCubage: boolean = false;
	private markPaidConfirm: boolean = false;
	private markFinCoupeConfirm: boolean = false;
	private markAvecGrumes: boolean = false;
	private markAvecBillons: boolean = false;
	private markBoisFiniConfirm: boolean = false;
	private markBillonsFiniConfirm: boolean = false;
	private putCoutM3Confirm: boolean = false;
	private markFinDebardageBillonsConfirm: boolean = false;
	private markFinDebardageGrumesConfirm: boolean = false;
	private setChauffeurDefaut: boolean = false;

	private editCubageChantier: CubageChantier = null;
	private editCubageChantierDocument: CubageChantier = null;
	
	private editLivraison: Livraison = null;
	private editLivraisonSeule: Livraison = null;
	private editLivraisonFromCubageChantier: Livraison = null;
	private editLivraisonFromCopie: Livraison = null;
	private cubageSourceForCreationLivraison: CubageChantier = null;
	private cubageLivraisonSourceForCreationLivraison: CubageLivraison = null;

	private deletedCubageChantier: CubageChantier = null;
	private editCubageLivraison: CubageLivraison = null;
	private newCubagePlateforme: CubagePlateforme = null;
	private editCubagePlateforme: CubagePlateforme = null;
	private deletedPlateforme: CubagePlateforme = null;
	private showCubageLivraisonForCubageChantier: CubageChantier = null;
	private editCubageLivraisonDocument: CubageChantier = null;
	private deletedCubageLivraison: CubageLivraison = null;
	private modifCubageLivraison: CubageLivraison = null;

	private storeCubageLivraison = storeCubageLivraison;
	private storeCubageChantier = storeCubageChantier;
	private storeCubagePlateforme = storeCubagePlateforme;
	private storeCubageChantierRestant = storeCubageChantierRestant;

	private get storePrestation(): Store<PrestationState> {
		return storePrestation;
	}

	private editChantier: Chantier = null;
	private editPhotosChantier: Chantier = null;
	private editChantierPlanning: Chantier = null;
	private editChantierFinancier: Chantier = null;
	private editFraisChantiers: Chantier = null;
	private editMessageOperateur: Chantier = null;
	
	private search = '';
	private headers = [];
	private headers2 = [];

	private totalCubageChantierM3: number = 0;
	private totalCubageChantierSteres: number = 0;
	private totalCoutsChantier: number = 0;
	private totalCubageChantierResteADebarderM3: number = 0;
	private documentsCount: number = 0;
	
	public constructor() {
		super(storeChantier);

		if (this.storeCubageLivraison.state.cubageLivraisons)
		{
			this.storeCubageLivraison.state.cubageLivraisons = null;
		}
		if (this.storeCubagePlateforme.state.cubagePlateformes)
		{
			this.storeCubagePlateforme.state.cubagePlateformes = null;
		}
		if (this.storeCubageChantierRestant.state.cubageChantierRestants)
		{
			this.storeCubageChantierRestant.state.cubageChantierRestants = null;
		}

		//console.log("constructor");
		this.isOperateurOnbly = this.me.isOperateurOnly();

		if (this.isGranted("API_GET_PURCHASE")) {
			this.headers = [
				{text: 'Type', align: 'start', value: 'boisType.name'},
				{text: 'Qualité', value: 'boisQuality'},
				{text: 'Taille', value: 'boisSize'},
				{text: 'Marquage', value: 'marquage'},
				{text: 'Scierie', value: 'scierie'},
				{text: 'Chauffeur', value: 'chauffeurParDefaut'},
				{text: 'Livraisons', value: 'actions1', sortable: false, filterable: false},
				{text: 'Vol. sous.', value: 'volumeInner'},
				{text: 'Coût exploitation', value: 'coutM3'},
				{text: '', value: 'camion', sortable: false, filterable: false},
				{text: 'Actions', value: 'actions2', sortable: false, filterable: false},
			];
		} else {
			this.headers = [
				{text: 'Type', align: 'start', value: 'boisType.name'},
				{text: 'Qualité', value: 'boisQuality'},
				{text: 'Taille', value: 'boisSize'},
				{text: 'Marquage', value: 'marquage'},
				{text: 'Scierie', value: 'scierie'},
				{text: 'Chauffeur', value: 'chauffeurParDefaut'},
				{text: 'Livraisons', value: 'actions1', sortable: false, filterable: false},
				{text: 'Vol. sous.', value: 'volumeInner'},
				{text: '', value: 'camion', sortable: false, filterable: false},
				{text: 'Actions', value: 'actions2', sortable: false, filterable: false},
			];
		}

		this.headers2 = [
				{text: 'Type', align: 'start', value: 'boisType.name'},
				{text: 'Qualité', value: 'boisQuality'},
				{text: 'Taille', value: 'boisSize'},
				{text: 'Marquage', value: 'marquage'},
				{text: 'Vol. à débarder.', value: 'volumeADebarder'},
			];
	}

	@Watch('chantier')
	public calcultotaux(): void {
//			console.log("calcultotaux");
		// on calcul le total
		this.totalCubageChantierM3 = 0;
		this.totalCubageChantierResteADebarderM3 = 0;
		this.totalCubageChantierSteres = 0;
		this.totalCoutsChantier = 0;
		this.documentsCount = 0;
		if (this.item) {
			for (const cubagechantier of this.item.cubageChantiers) {
				if (cubagechantier.boisSize.grume == false)
					this.totalCubageChantierSteres = this.totalCubageChantierSteres + cubagechantier.volumeStere;
				else
					this.totalCubageChantierM3 = this.totalCubageChantierM3 + cubagechantier.volumeInner;

				if (cubagechantier.resteADebarder)
				{
					this.totalCubageChantierResteADebarderM3 = this.totalCubageChantierResteADebarderM3 + (cubagechantier.volumeInnerAvantDebardage - cubagechantier.volumeInner);
				}
				this.totalCoutsChantier = this.totalCoutsChantier + (cubagechantier.volumeInner * cubagechantier.coutM3);
			}

			for (const presta of this.item.prestations) {
				this.documentsCount = this.documentsCount + presta.documents.length;
			}
		}
	}

	public async mounted(): Promise<void> {
		await super.mounted();
	}

	public get canAddCubage(): boolean {
		return !!this.item && !!this.item.prestations.filter(p => !p.prestationType.sale).length;
	}

	@Emit()
	private createCubageChantier() {
		this.editCubageChantier = new CubageChantier();
		this.editCubageChantier.chantier = this.item;
		this.editCubageChantier.chauffeurParDefaut = this.item.chauffeurParDefaut;
		this.editCubageChantier.coef = 0.9;
		this.editCubageChantier.coefStere = 0.63;
		this.editCubageChantier.coutM3 = this.item.coutM3Defaut;

		const prestations = this.item.prestations
			.filter(p => !p.prestationType.sale)
		;
		const total = prestations.reduce((a, p) => a + p.price, 0);

		/*this.editCubageChantier.repartitions = prestations
			.map(p => {
				const repartition = new CubageChantierRepartiton()
				repartition.prestation = p;
				repartition.percent = Math.round(p.price / total * 100) / 100;
				return repartition;
			})
		;*/
	}

	@Emit()
	private createLivraison(): void {
		//console.log(oCubageChantier);
		this.editLivraison = new Livraison();
	}

	@Emit()
	private AddCubageLivraison(): void {
			var oLivraison  = (storeLivraison.state as PaginatorState).current;
			storeLivraison.state.CounterCreation = 1;
			this.editCubageLivraison = new CubageLivraison();

			this.editCubageLivraison.date = oLivraison.date;
			this.editCubageLivraison.livraison = oLivraison;
			this.editCubageLivraison.bl = oLivraison.bl;
			this.editCubageLivraison.numfacture = oLivraison.numfacture;
			this.editCubageLivraison.scierie = oLivraison.scierie;
			this.editCubageLivraison.chauffeur = oLivraison.chauffeur;
			this.editCubageLivraison.chantier = this.item;
			this.editCubageLivraison.coef = 0.9;
			this.editCubageLivraison.coefStere = 0.63;
			this.editCubageLivraison.boisFini = false;
	}

	private async LanceEditLivraison(item): Promise<void> 
		{
			var oNew = new Livraison();
			oNew.id = item.livraison.id;
			this.editLivraisonSeule = oNew; // await storeLivraison.dispatch('get', item.livraison.id);
		}

	private AddEncoreCubageLivraison()
	{
		if (storeLivraison.state.nextcubage_meme_chantier == true)
		{
			storeLivraison.state.CounterCreation += 1;
			var oLivraison  = (storeLivraison.state as PaginatorState).current;
			this.editCubageLivraison = new CubageLivraison();

			this.editCubageLivraison.date = oLivraison.date;
			this.editCubageLivraison.chantier = storeLivraison.state.PreviousCubageLivraison.chantier;
			this.editCubageLivraison.livraison = oLivraison;
			this.editCubageLivraison.bl = oLivraison.bl;
			this.editCubageLivraison.numfacture = oLivraison.numfacture;
			this.editCubageLivraison.scierie = oLivraison.scierie;
			this.editCubageLivraison.chauffeur = oLivraison.chauffeur;
			this.editCubageLivraison.coef = 0.9;
			this.editCubageLivraison.coefStere = 0.63;
			this.editCubageLivraison.boisFini = false;
		}
		else
		{
			if (storeLivraison.state.nextcubage_autre_chantier == true)
			{
				storeLivraison.state.CounterCreation += 1;
				var oLivraison  = (storeLivraison.state as PaginatorState).current;
				this.editCubageLivraison = new CubageLivraison();

				this.editCubageLivraison.date = oLivraison.date;
				this.editCubageLivraison.livraison = oLivraison;
				this.editCubageLivraison.bl = oLivraison.bl;
				this.editCubageLivraison.numfacture = oLivraison.numfacture;
				this.editCubageLivraison.scierie = oLivraison.scierie;
				this.editCubageLivraison.chauffeur = oLivraison.chauffeur;
				this.editCubageLivraison.coef = 0.9;
				this.editCubageLivraison.coefStere = 0.63;
				this.editCubageLivraison.boisFini = false;
			}
		}
	}

	@Emit()
	private createCubagePlateforme(): void {
		this.newCubagePlateforme = new CubagePlateforme();
		this.newCubagePlateforme.chantier = this.item;
		this.newCubagePlateforme.coef = 0.9;
		this.newCubagePlateforme.coefStere = 0.63;
	}

	@Emit()
	private createLivraisonFromCubageChantier(cubage: CubageChantier): void {
		//console.log(oCubageChantier);
		this.editLivraisonFromCubageChantier = new Livraison();
		this.editLivraisonFromCubageChantier.scierie = cubage.scierie;
		this.editLivraisonFromCubageChantier.chauffeur = cubage.chauffeurParDefaut;

		this.cubageSourceForCreationLivraison = cubage;
	}

	@Emit()
	private async AddCubageLivraisonFromCubageChantier(): Promise<void> {
		var oLivraison  = (storeLivraison.state as PaginatorState).current;
		storeLivraison.state.CounterCreation = 1;

		this.editCubageLivraison = new CubageLivraison();

		this.editCubageLivraison.date = oLivraison.date;
		this.editCubageLivraison.livraison = oLivraison;
		this.editCubageLivraison.bl = oLivraison.bl;
		this.editCubageLivraison.numfacture = oLivraison.numfacture;
		this.editCubageLivraison.scierie = oLivraison.scierie;
		this.editCubageLivraison.chauffeur = oLivraison.chauffeur;

		this.editCubageLivraison.chantier = this.item;
		this.editCubageLivraison.boisType = this.cubageSourceForCreationLivraison.boisType;
		this.editCubageLivraison.boisQuality = this.cubageSourceForCreationLivraison.boisQuality;
		this.editCubageLivraison.boisSize = this.cubageSourceForCreationLivraison.boisSize;
		this.editCubageLivraison.marquage = this.cubageSourceForCreationLivraison.marquage;
		if (this.editCubageLivraison.marquage == null) {
			this.editCubageLivraison.sansMarquage = true;
		}

		this.editCubageLivraison.coef = 0.9;
		this.editCubageLivraison.coefStere = 0.63;

		// Si on trouve un cubage restant
		var result: string = '';
		var marquageID: string = '0';
		if (this.cubageSourceForCreationLivraison.marquage)
		{
			marquageID = this.cubageSourceForCreationLivraison.marquage.id.toString();
		}

		await axios.get(process.env.VUE_APP_BACK_BASE_URL + "/api/cubage-chantier-restants/get_reste/" + this.item.id + "/" + this.cubageSourceForCreationLivraison.boisType.id + "/" +  this.cubageSourceForCreationLivraison.boisQuality.id + "/" + this.cubageSourceForCreationLivraison.boisSize.id + "/" + marquageID + "?token=" + this.token.id, {}).then(async response => {
				result = await response.data;
		});
		//console.log(result);
		this.editCubageLivraison.volumeEstimeRestant = result;
	}

	@Emit()
	private createLivraisonFromCopie(cubage: CubageLivraison): void {
		//console.log(oCubageChantier);
		this.editLivraisonFromCopie = new Livraison();
		this.editLivraisonFromCopie.scierie = cubage.scierie;
		this.editLivraisonFromCopie.chauffeur = cubage.chauffeur;

		this.cubageLivraisonSourceForCreationLivraison = cubage;
	}

	private AddCubageLivraisonFromCopie(): void {
		var oLivraison  = (storeLivraison.state as PaginatorState).current;
		storeLivraison.state.CounterCreation = 1;

		// On copie le cubage
		const newCubage = new CubageLivraison();

		newCubage.date = oLivraison.date;
		newCubage.livraison = oLivraison;
		newCubage.bl = oLivraison.bl;
		newCubage.numfacture = oLivraison.numfacture;
		newCubage.scierie = oLivraison.scierie;
		newCubage.chauffeur = oLivraison.chauffeur;

		newCubage.chantier = this.item;
		//newCubage.date = now;
		newCubage.estimer = this.cubageLivraisonSourceForCreationLivraison.estimer;
		newCubage.volumeApproximatif = this.cubageLivraisonSourceForCreationLivraison.volumeApproximatif;
		newCubage.boisType = this.cubageLivraisonSourceForCreationLivraison.boisType;
		newCubage.boisQuality = this.cubageLivraisonSourceForCreationLivraison.boisQuality;
		newCubage.boisSize = this.cubageLivraisonSourceForCreationLivraison.boisSize;
		newCubage.marquage = this.cubageLivraisonSourceForCreationLivraison.marquage;
		newCubage.volumeInner = this.cubageLivraisonSourceForCreationLivraison.volumeInner;
		newCubage.volumeOutter = this.cubageLivraisonSourceForCreationLivraison.volumeOutter;
		newCubage.coef = this.cubageLivraisonSourceForCreationLivraison.coef;
		newCubage.prixM3 = this.cubageLivraisonSourceForCreationLivraison.prixM3;
		newCubage.prixCamion = this.cubageLivraisonSourceForCreationLivraison.prixCamion;
		newCubage.prixStere = this.cubageLivraisonSourceForCreationLivraison.prixStere;
		newCubage.volumeTonne = this.cubageLivraisonSourceForCreationLivraison.volumeTonne;
		newCubage.volumeStere = this.cubageLivraisonSourceForCreationLivraison.volumeStere;
		newCubage.coefStere = this.cubageLivraisonSourceForCreationLivraison.coefStere;
		newCubage.coefTonne = this.cubageLivraisonSourceForCreationLivraison.coefTonne;
		if (newCubage.marquage == null) {
			newCubage.sansMarquage = true;
		}

		this.editCubageLivraison = newCubage;
	}

	@Emit()
	private showCubageLivraisonFromCubageChantier(cubage: CubageChantier): void {
		this.showCubageLivraisonForCubageChantier = cubage;
	}

	private filterCubageLivraisonFromCubageChantier(cubage: CubageChantier): void {
		this.filtersLivraisons.clear();
		this.filtersLivraisons.chantier = [this.item];
		this.filtersLivraisons.boisType = [cubage.boisType];
		this.filtersLivraisons.boisSize = [cubage.boisSize];
		this.filtersLivraisons.boisQuality = [cubage.boisQuality];
	}

	private effacerFiltres(): void {
		this.filtersLivraisons.clear();
		this.filtersLivraisons.chantier = [this.item];
	}

	private async ClotureBilan(): Promise<void> {
		try {
			this.item.financialClose = true;
			await storeChantier.dispatch('put', this.item);
		} catch (e) {
			this.notify('Une erreur est survenu', 'error');
		}
	}

	private async OpenBilan(): Promise<void> {
		try {
			this.item.financialClose = false;
			await storeChantier.dispatch('put', this.item);
		} catch (e) {
			this.notify('Une erreur est survenu', 'error');
		}
	}

	private async CalculBilan(): Promise<void> {
		try {
			await storeChantier.dispatch('calculBilan', this.item);
			await storeChantier.dispatch('get', this.item.id);

		} catch (e) {
			this.notify('Une erreur est survenu', 'error');
		}
	}

	private async onAddPrestation(): Promise<void> {
		storeLoader.commit('push');
		try {
			const prestations = await Promise.all(
				this.addPrestationList.map(prestation => storePrestation.dispatch('get', prestation.id))
			);
			for (const prestation of prestations) {
				if (!this.item.commune || this.item.commune == '') this.item.commune = prestation.commune;
				if (!this.item.GPS || this.item.GPS == '') this.item.GPS = prestation.GPS;
				if (!this.item.client || this.item.client == '') this.item.client = prestation.contrat.tiers.name;
				prestation.chantier = this.item;
				await storePrestation.dispatch('put', prestation);
			}

			await storeChantier.dispatch('put', this.item);

			await Promise.all([
				storePrestation.dispatch('getC', {filters: true}),
				storeChantier.dispatch('get', this.item.id),
			]);
			this.addPrestationList = [];
			this.addPrestationDialog = false;
			this.refresh(this.item, true);
		} catch (e) {
			console.error(e);
			this.notify('Une erreur est survenu', 'error');
		}
		storeLoader.commit('pop');
	}

	private async refreshCubageChantier(): Promise<void> {
		//storeCubageChantier.dispatch('getC', { filters: true });
		this.refresh(this.item, true);
	}

	private async refreshCubageLivraison(): Promise<void> {
		//storeCubageLivraison.dispatch('getC', { filters: true });
		this.refresh(this.item, true);
	}

	private async refreshCubagePlateforme(): Promise<void> {
		await storeCubagePlateforme.dispatch('getC', {filters: true});
		this.refresh(this.item, true);
		await storeCubageChantierRestant.dispatch('getC', {filters: true});
	}

	private async prestationMarkUnpaid(prestation: Prestation): Promise<void> {
		await storePrestation.dispatch('putMarkUnpaid', prestation);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async prestationMarkPaid(prestation: Prestation): Promise<void> {
		await storePrestation.dispatch('putMarkPaid', prestation);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkPaid(): Promise<void> {
		await storeChantier.dispatch('putMarkPaid', this.item);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkFinCoupe(): Promise<void> {
		await storeChantier.dispatch('putMarkFinCoupe', this.item);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkDebut(): Promise<void> {
		await storeChantier.dispatch('putMarkDebut', this.item);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkFin(): Promise<void> {
		await storeChantier.dispatch('putMarkFin', this.item);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkAvecGrumes(): Promise<void> {
		await storeChantier.dispatch('putMarkAvecGrumes', this.item);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkAvecBillons(): Promise<void> {
		await storeChantier.dispatch('putMarkAvecBillons', this.item);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkDebardageGrumes(): Promise<void> {
		await storeChantier.dispatch('putMarkDebardageGrumes', this.item);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkDebardageBillons(): Promise<void> {
		await storeChantier.dispatch('putMarkDebardageBillons', this.item);
		await Promise.all([
			storePrestation.dispatch('getC', {filters: true}),
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkBoisFini(): Promise<void> {
		await storeChantier.dispatch('putMarkBoisFini', this.item);
		await Promise.all([
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierMarkBillonsFini(): Promise<void> {
		await storeChantier.dispatch('putMarkBillonsFini', this.item);
		await Promise.all([
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierSetChauffeurCubages(): Promise<void> {
		await storeChantier.dispatch('setChauffeurCubages', this.item);
		await Promise.all([
			storeChantier.dispatch('get', this.item.id),
		]);
	}

	private async chantierPutCoutM3(): Promise<void> {
		await storeChantier.dispatch('putCoutM3', this.item);
		this.refresh(this.item, true);
	}

	private async getIdClient() {
		this.storePrestation.state.prestations.data.forEach(element => {
			if (element.contrat.tiers.name === this.item.client) {
				this.$router.push({name: 'tiers-show', params: {id: element.contrat.tiers.id.toLocaleString()}});
			}
		});
	}

	private get hrefPdf(): string {
		return `${process.env.VUE_APP_BACK_BASE_URL}/pdf/chantier/${this.item.id}/recap?token=${this.token.id}`;
	}
}














































































































































	import {Component} from '@/shared/component';
	import {AbstractEditVue} from '@/components/Abstract/AbstractEditVue';
	import {TiersType, CubageChantierTest, User} from '@/models';

	import storeCubageChantierTest from '@/stores/modules/cubageChantierTest';
    import validator from "@/shared/validator";

	import InputBoisType from '@/components/Input/BoisType.vue';
	import InputMarquage from '@/components/Input/Marquage.vue';
	import {Stored} from 'vue-stored-prop-decorator/index';
	import storeUser from '@/stores/modules/user';
	import InputDatePicker from '@/components/Input/DatePicker.vue';

	@Component({
		components: {
			InputDatePicker,
			InputBoisType,
			InputMarquage,
		}
	})
	export default class CrudCubageChantierTestEdit extends AbstractEditVue<CubageChantierTest> {

        validator = validator

		public constructor() {
			super(
				storeCubageChantierTest,
				'Cubage modifié',
				'Cubage créé',
			);
		}

		public onChange ()
		{
		}

		public async mounted(): Promise<void> {
			await super.mounted();
			
		}
}

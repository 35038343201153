








































    import {Emit} from "vue-property-decorator";
	import {InOut} from 'vue-inout-prop-decorator';
	import {Component, Vue} from '@/shared/component';
	
	import {CubageChantierTest} from '@/models';
	
	import CrudCubageChantierTestEdit from '@/components/Crud/CubageChantierTest/Edit.vue';
	
	@Component({
		components: {
			CrudCubageChantierTestEdit,
		}
	})
	export default class CrudCubageChantierTestEditDialog extends Vue {
		@InOut({ type: CubageChantierTest, isVModel: true }) private value!: CubageChantierTest;

        @Emit()
		private onSubmit(cubageChantierTest: CubageChantierTest) {
			this.onClose();
		}

		@Emit()
		private onClose() {
			this.value = null;
		}
	}














































































	import {Prop, Watch} from "vue-property-decorator";
	import {Component, Vue} from '@/shared/component';
	import storeTiers from '@/stores/modules/tiers';
  	import Checked from '@/components/Checked.vue';

	import {
		DxDataGrid,
		DxColumn,
		DxEditing,
		DxFilterRow,
		DxHeaderFilter,
		DxGroupPanel,
		DxGrouping,
		DxScrolling,
		DxSummary,
		DxLookup,
		DxTotalItem,
		DxGroupItem,
		DxMasterDetail,
		DxStringLengthRule,
		DxRequiredRule,
		DxRangeRule,
		DxValueFormat,
		DxPaging,
		DxStateStoring,
		DxPager,
	} from 'devextreme-vue/data-grid';
	import { createStore } from 'devextreme-aspnet-data-nojquery';
	import DxButton from 'devextreme-vue/button';
	import storeToken from "@/stores/modules/token";
	import {Facture, Tiers, User, Token} from "@/models";
	import {Stored} from "vue-stored-prop-decorator";
	import storeUser from '@/stores/modules/user';
	import {Emit} from 'vue-property-decorator';

	@Component({
		components: {
      		Checked,
			DxDataGrid,
			DxColumn,
			DxEditing,
			DxFilterRow,
			DxHeaderFilter,
			DxGroupPanel,
			DxGrouping,
			DxScrolling,
			DxSummary,
			DxLookup,
			DxTotalItem,
			DxGroupItem,
			DxMasterDetail,
			DxStringLengthRule,
			DxRangeRule,
			DxRequiredRule,
			DxValueFormat,
			DxPaging,
			DxPager,
			DxStateStoring,
			DxButton,
		}
	})
	export default class CrudTiersReglementsList extends Vue {
		@Prop({ type: Tiers, default: null}) public client!: Tiers;

		@Stored(() => storeToken) token!: Token;
		@Stored(() => storeUser) me!: User; 

		private storeTiers = storeTiers;
		private dataGrid;

		private url = process.env.VUE_APP_BACK_BASE_URL + '/api/tierses'

		public clearFilters() {
			this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
			this.dataGrid.instance.clearFilter()
		}

		private dataSource = null;

		@Watch('client')
		public watcher(): void {

			console.log("On watcher client");

			if (this.client != null)
			{
				this.dataSource = createStore({
				key: 'id',
				loadUrl: `${this.url}/fetch_reglements/${this.client.id}`,
				onBeforeSend: (method, ajaxOptions) => {
					ajaxOptions.xhrFields = { withCredentials: true };
					ajaxOptions.headers = {
						'Authorization': 'BEARER ' + this.token.id
					}
				},
				});

				this.dataGrid = this.$refs['dataGridRefKey'] as DxDataGrid
				this.dataGrid.instance.refresh()
			}
		}

		private calculateDate(data) {
			if (data.date != null) {
				let date = data.date
				let year = date.substr(0,4)
				let month = date.substr(5,2)
				let day = date.substr(8,2)
				return day + '/' + month + '/' + year
			} else {
				return data.date;
			}
		}

		public onRowPrepared(e) {
            if (e.rowType === "data") {
                if ((e.dataIndex % 2) != 0) {
                    e.rowElement.style.cssText = "background-color: #eeeeee;";
				}
                if (e.data.AlertePaid == "1") {
                    e.rowElement.style.cssText = "color: red; background-color: #cce6ff;";
                    // or
                    //e.rowElement.classList.add("my-class");
                    // To override alternation color
                    //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
                }
            }
        }		
	}

import {XHTTP} from '@/shared/xhttp';
import {CubageChantierTestService} from '@/xhttp';
import {Chantier} from './Chantier';
import {BoisType} from "@/models/BoisType";
import {Marquage} from "@/models/Marquage";
import {DateHelper} from "@/shared/utils";

@XHTTP(CubageChantierTestService, '/cubage-chantier-tests')
export class CubageChantierTest {
	
	private _id: number;
	private _date: Date = new Date();
	private _chantier: Chantier;
	private _boisType: BoisType;
	private _marquage: Marquage;
	private _surEcorce: boolean;
	private _longueurBilleMachine: number;
	private _longueurBilleManuel: number;
	private _diametreBilleMachine: number;
	private _diametreBilleManuel: number;
	private _longueurCreteMachine: number;
	private _longueurCreteManuel: number;
	private _diametreCreteMachine: number;
	private _diametreCreteManuel: number;
	private _numero: number;
	private _username: string;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get date(): Date {
		return this._date;
	}

	public get chantier(): Chantier {
		return this._chantier;
	}

	public get boisType(): BoisType {
		return this._boisType;
	}

	public get marquage(): Marquage {
		return this._marquage;
	}

	public get surEcorce(): boolean {
		return this._surEcorce;
	}

	public get longueurBilleMachine(): number {
		return this._longueurBilleMachine;
	}
	
	public get longueurBilleManuel(): number {
		return this._longueurBilleManuel;
	}

	public get diametreBilleMachine(): number {
		return this._diametreBilleMachine;
	}

	public get diametreBilleManuel(): number {
		return this._diametreBilleManuel;
	}

	public get longueurCreteMachine(): number {
		return this._longueurCreteMachine;
	}
	
	public get longueurCreteManuel(): number {
		return this._longueurCreteManuel;
	}

	public get diametreCreteMachine(): number {
		return this._diametreCreteMachine;
	}

	public get diametreCreteManuel(): number {
		return this._diametreCreteManuel;
	}

	public get numero(): number {
		return this._numero;
	}

	public get username(): string {
		return this._username;
	}

	/////////////
	// Setters //
	/////////////

	public set date(value: Date) {
		this._date = value;
	}

	public set id(value: number) {
		this._id = value;
	}

	public set chantier(value: Chantier) {
		this._chantier = value;
	}

	public set surEcorce(value: boolean) {
		this._surEcorce = value;
	}

	public set boisType(value: BoisType) {
		this._boisType = value;
	}

	public set marquage(value: Marquage) {
		this._marquage = value;
	}

	public set longueurBilleMachine(value: number) {
		this._longueurBilleMachine = value;
	}

	public set longueurBilleManuel(value: number) {
		this._longueurBilleManuel = value;
	}

	public set diametreBilleMachine(value: number) {
		this._diametreBilleMachine = value;
	}

	public set diametreBilleManuel(value: number) {
		this._diametreBilleManuel = value;
	}

	public set longueurCreteMachine(value: number) {
		this._longueurCreteMachine = value;
	}

	public set longueurCreteManuel(value: number) {
		this._longueurCreteManuel = value;
	}

	public set diametreCreteMachine(value: number) {
		this._diametreCreteMachine = value;
	}

	public set diametreCreteManuel(value: number) {
		this._diametreCreteManuel = value;
	}

	public set numero(value: number) {
		this._numero = value;
	}

	public set username(value: string) {
		this._username = value;
	}

	/////////
	// Add //
	/////////


	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id          : this._id,
			date    : this._date ? DateHelper.toLocalizedString(this._date) : null,
			chantier    : this._chantier ? this._chantier.id : null,
			surEcorce      : this._surEcorce,
			boisType    : this._boisType ? this._boisType.id : null,
			marquage    : this._marquage ? this._marquage.id : null,
			longueurBilleMachine : this._longueurBilleMachine,
			longueurBilleManuel : this._longueurBilleManuel,
			diametreBilleMachine : this._diametreBilleMachine,
			diametreBilleManuel : this._diametreBilleManuel,
			longueurCreteMachine : this._longueurCreteMachine,
			longueurCreteManuel : this._longueurCreteManuel,
			diametreCreteMachine : this._diametreCreteMachine,
			diametreCreteManuel : this._diametreCreteManuel,
			numero : this._numero,
			username : this._username,
		};
	}
	
	public fromJSON(data: any): this {
		this._id           = data.id;
		this._date         = data.date ? DateHelper.fromLocalizedString(data.date) : null;
		this._chantier     = data.chantier ? (new Chantier()).fromJSON(data.chantier) : null;
		this._surEcorce       = data.surEcorce;
		this._boisType     = data.boisType ? (new BoisType()).fromJSON(data.boisType) : null;
		this._marquage     = data.marquage ? (new Marquage()).fromJSON(data.marquage) : null;
		this._longueurBilleMachine  = data.longueurBilleMachine;
		this._longueurBilleManuel  = data.longueurBilleManuel;
		this._diametreBilleMachine  = data.diametreBilleMachine;
		this._diametreBilleManuel  = data.diametreBilleManuel;
		this._longueurCreteMachine  = data.longueurCreteMachine;
		this._longueurCreteManuel  = data.longueurCreteManuel;
		this._diametreCreteMachine  = data.diametreCreteMachine;
		this._diametreCreteManuel  = data.diametreCreteManuel;
		this._numero  = data.numero;
		this._username  = data.username;


		return this;
	}
}
